import { useProfileData } from '../AuthProvider'
import Chat from '../Chat'
import Template from '../Template'
import * as style from './style.module.scss'
import { navigate } from 'gatsby-link'
import React, { useEffect } from 'react'

const PageMessage = () => {
  const profile = useProfileData()
  const params = new URLSearchParams(
    typeof window !== 'undefined' && window.location.search
  )
  const channel = params.get('c')

  useEffect(() => {
    if (!channel) {
      navigate(`?c=${Math.random().toString(16).slice(2)}`)
    }
  }, [channel])

  if (!profile) {
    return null
  }

  return (
    <Template fullWidth>
      <div className={style.pageMessage}>
        <Chat
          apiKey={process.env.GATSBY_STREAM_API_KEY}
          userToConnect={{ id: profile.user_id }}
          userToken={
            typeof window !== 'undefined' && localStorage.getItem('doggos_l')
          }
          channel={channel}
        />
      </div>
    </Template>
  )
}

export default PageMessage
